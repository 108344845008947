.add-score-form {
    max-width: 650px;
    margin: 0 auto;
    padding: 10px;
    background-color: #fff3e0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-score-form h2 {
    color: #d35400;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.form-group label {
    flex-basis: 150px; /* Fixed width for labels */
    margin-right: 10px;
    font-weight: bold;
    color: #e67e22;
    font-size: 1.2rem;
}

.form-group input {
    flex-basis: 300px; /* Fixed width for labels */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.form-group-checkbox input {
    flex-basis: 300px; /* Fixed width for labels */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group-checkbox label {
    flex-basis: 150px; /* Fixed width for labels */
    margin-right: 10px;
    font-weight: bold;
    color: #e67e22;
    font-size: 1.2rem;
}

button[type="submit"] {
    width: 30%;
    align-items: center;
    padding: 10px;
    background-color: orange;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: darkorange;
}


.radio-group {
    display: flex;
    gap: 15px;
}

.radio-group label {
    display: grid;
    align-items: center;
    font-size: 1.1rem;
}


.toggle-additional-info {
    width: 100%;
    padding: 3px;
    margin-top: 10px;
    background-color:#b6a98d;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: zoom-in;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.toggle-additional-info:hover {
    background-color: #b29454;
}

.additional-info {
    margin-top: 20px;
    padding: 10px;
    border-radius: 8px;
}
input[type="radio"]  {
    accent-color: #FF8C00;
}

input[type="checkbox"]  {
    accent-color: #FF8C00;
}
