
.quiz-image {
    width: 70%;
    height: auto;
    display: block;
    margin: 0 auto 20px; /* Center horizontally with margin auto */
}


.quiz-option {
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    background-color: orange;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 10px;    
}
.quiz-option:hover {
    background-color: darkorange;
}

.correct {
    background-color: green;
    color: white;   
}
.correct:hover {
    background-color: forestgreen;
}
.wrong {
    background-color: red;
    color: white;
}
.wrong:hover {
    background-color: crimson;
    color: white;
}


.quiz-option:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.score-display {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: orange;
}

.question-text {
    color: #e67e22; /* Slightly lighter orange for section headers */
    font-size: 20px;
    margin-bottom: 10px;
}

.answer-reason {
    color: darkorange; /* Slightly lighter orange for section headers */
    margin-bottom: 10px;
}