.highscore-list {
    background-color: #fff3e0;
    padding: 20px;
    border-radius: 15px;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.highscore-list h2 {
    color: #d35400;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.highscore-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.highscore-list li {
    background-color: #ffe0b2;
    padding: 15px;
    margin: 10px 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s ease-in-out;
}

.highscore-list li:hover {
    transform: translateY(-5px);
}

.highscore-list li strong {
    color: #e67e22;
    font-size: 1.2rem;
}

.refresh-button {
    background-color: #ff9900;
    color: #fff;
    border: #ff9900;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
}

.refresh-button:hover {
    background-color: #e68a00;
}
.score-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center; /* Center items vertically */
    width: 99%; /* Make each score item take up 99% of the parent's width */
    margin: 0 auto; /* Center the score item horizontally */
}

.score-verified {
    position: relative;
}

/* Tooltip container */
.score-verified::after {
    content: "Denne klementinen er godkjent av administratorane!";
    position: absolute;
    bottom: 100%; /* Position above the check-mark */
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff9900;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 0.75em;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none; /* Prevents the tooltip from being interacted with */
}

/* Show tooltip on hover */
.score-verified:hover::after {
    opacity: 0.7;
    visibility: visible;
}