.advent-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);  /* 6 columns */
    gap: 10px;
    justify-items: center;
    align-items: center;
    

}

.grid-container {
    background-color: #fff3e0;
    padding: 20px;
    border-radius: 15px;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

}

.advent-item {
    position: relative;
    width: 150px;   /* Adjust the size to your needs */
    height: 100px;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.advent-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.overlay-number {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);  /* Dark transparent background behind the number */
    pointer-events: none;  /* Prevents number from interfering with clicks */
}
.overlay-number.dark-overlay {
    background-color: rgba(0, 0, 0, 0.8); /* Darker background for future days */
    cursor: default;

}

.future-day .advent-image {
    filter: grayscale(100%); /* Optional: Make future images grayscale */
    cursor: default;

}
