/* src/Header.css */
.header {
    background-color: #FF8C00; /* Dark orange background */
    padding-left: 20px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-link {
    background-color: #FFA500; /* Lighter orange for buttons */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-bottom: 15px;
}

.nav-link:hover {
    background-color: #FF4500; /* Even darker orange on hover */
}

.nav-link:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(255, 140, 0, 0.5);
}

.nav-link:active {
    background-color: #FF6347; /* Slightly different color when clicked */
}
