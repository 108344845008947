.music-player-container {
    background-color: #fff3e0;
    padding: 20px;
    border-radius: 15px;
    margin: 20px 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.music-player-container h2 {
    color: #d35400;
    font-size: 24px;
    margin-bottom: 10px;
}

.music-player-container p {
    color: #e67e22;
    font-size: 18px;
    margin-bottom: 20px;
}

.music-player-container button {
    padding: 10px 20px;
    background-color: #FFA500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.music-player-container button:hover {
    background-color: #FF4500;
}

.musikk-image {
    width: 90%;
    height: auto;
    display: block;
    margin: 0 auto 20px; /* Center horizontally with margin auto */
}

.slider {
    width: 100%;
    margin: 10px 0;
}

.time-display {
    font-size: 14px;
    color: #ff9900;
    margin-top: 5px;
}

.music-button {
    margin: 0 20px 20px 0;
}
