.calendar-container {
    background-color: #fff3e0;
    padding: 20px;
    border-radius: 15px;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}


.calendar-image {
    cursor: pointer;
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto 20px;
    transition: transform 0.3s ease; /* Smooth transition */
}

.rotate-animation {
    transform: rotateY(90deg); /* Rotate 180 degrees along Y-axis */
}

.spin-animation {
    animation: spin 0.5s linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.back-button {
    width: 30%;
    align-items: center;
    padding: 10px;
    background-color: orange;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: darkorange;
}

.calendar-image:hover {
    opacity: 0.8;
}



@keyframes fall {
    0% {
        transform: translateY(-10vh);
    }
    100% {
        transform: translateY(110vh);
    }
}
.snowflake {
    position: absolute;
    top: -100px;
    font-size: 24px;
    color: white;
    opacity: 0.9;
    user-select: none;
    animation: fall linear infinite;
    z-index: 1000;
}

.snowflakes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Snowflakes shouldn't block clicking */
    overflow: hidden;
    z-index: 1001;
}

/* Randomize the snowflake sizes and speed */
.snowflake:nth-child(1) { left: 10%; animation-duration: 7s; animation-delay: 0s; }
.snowflake:nth-child(2) { left: 20%; animation-duration: 9s; animation-delay: 1s; }
.snowflake:nth-child(3) { left: 30%; animation-duration: 6s; animation-delay: 2s; }
.snowflake:nth-child(4) { left: 40%; animation-duration: 8s; animation-delay: 0s; }
.snowflake:nth-child(5) { left: 50%; animation-duration: 7s; animation-delay: 2.5s; }
.snowflake:nth-child(6) { left: 60%; animation-duration: 9s; animation-delay: 0.5s; }
.snowflake:nth-child(7) { left: 70%; animation-duration: 9s; animation-delay: 0s; }
.snowflake:nth-child(8) { left: 80%; animation-duration: 6s; animation-delay: 1s; }
.snowflake:nth-child(9) { left: 90%; animation-duration: 7s; animation-delay: 0.5s; }
.snowflake:nth-child(10) { left: 15%; animation-duration: 9s; animation-delay: 1.5s; }
.snowflake:nth-child(11) { left: 25%; animation-duration: 8s; animation-delay: 1.5s; }
.snowflake:nth-child(12) { left: 35%; animation-duration: 7s; animation-delay: 3.5s; }
.snowflake:nth-child(13) { left: 45%; animation-duration: 8s; animation-delay: 4.5s; }
.snowflake:nth-child(14) { left: 55%; animation-duration: 9s; animation-delay: 3s; }
.snowflake:nth-child(15) { left: 65%; animation-duration: 6s; animation-delay: 1s; }
.snowflake:nth-child(16) { left: 75%; animation-duration: 7s; animation-delay: 4.5s; }
.snowflake:nth-child(17) { left: 85%; animation-duration: 8s; animation-delay: 3.5s; }