.rules-container {
    background-color: #fff3e0; /* Light orange background */
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.rules-container h2 {
    color: #d35400; /* Darker orange for the main header */
    font-size: 24px;
    margin-bottom: 20px;
}

.rules-section {
    margin-bottom: 20px;
}

.rules-section h3 {
    color: #e67e22; /* Slightly lighter orange for section headers */
    font-size: 20px;
    margin-bottom: 10px;
}

.rules-section p {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
}

.rules-section ul {
    list-style-type: disc;
    padding-left: 20px;
}

.rules-section li {
    margin-bottom: 8px;
    color: #555;
}
