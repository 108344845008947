/* src/Home.css */
.home {
    text-align: center;
    padding: 10px 10px 10px 10px;
    background-color: #fff3e0; /* Light orange background */
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);


}

.home-image {
    width: 100%;
    max-width: 600px;
    margin: 20px 0;
}

.home p {
    font-size: 20px;
}
